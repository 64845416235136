import apiSecure from "../../services/servicedba";
import { minLengPassword, PATH_DELETE_EVENT_BGMAIN_BYID, PATH_GET_DISTANCES, PATH_GET_DISTANCE_EVENT_BYID, PATH_GET_EVENTS_BGMAIN, PATH_GET_EVENTS_TABLE, PATH_GET_EVENT_BGMAIN_BYID, PATH_GET_EVENT_ID, PATH_GET_SUSCRIBERS_TALLAS, PATH_GET_SUSCRIBER_ID, PATH_GET_SUSCRIBER_TABLE, PATH_GET_USER, PATH_LOGIN, PATH_SAVE_DISTANCE_EVENT, PATH_SAVE_EVENTS, PATH_SAVE_EVENTS_BGMAIN, PATH_SUBMITRESULTADOS, PATH_UPDATE_EVENT_BGMAIN_BYID, PATH_UPDATE_EVENT_ID, PATH_UPDTE_SUSCRIBER, PATH_DELETE_EVENTS_TABLE } from "../../services/utils";



export const postLogin = async (credentials) => {

    let resp = await apiSecure.post(PATH_LOGIN, credentials)
    return resp.data
}

export const getUserToValidateToken = async () => {

    let resp = await apiSecure.post(PATH_GET_USER, { token: localStorage.getItem('token') })
    return resp.data

}

export const validateFields = (fielValue, type, isrequire, FieldName) => {
    let resp = { success: true, mensg: '' }
    if (isrequire) {
        if (fielValue === "") return resp = { success: false, mensg: "The field [" + FieldName + "] is required" }
    }

    switch (type) {
        case 'email':
            let emailValid = fielValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!emailValid) resp = { success: false, mensg: "This email is not valid" }

            break;
        case 'password':
            let passwordValid = fielValue.length >= minLengPassword;
            if (!passwordValid) resp = { success: false, mensg: "This password min characters is " + minLengPassword }

            break;

        default:
            break;
    }

    return resp

}

export const handlerErrorResponse = (err) => {



    let resp = ""
    if (err.response) {

        if (err.response.status === 401) {

            resp = err.response.data.message + ": " + err.response.statusText
            //REDIRECCIONAR AL LOGIN
        }

        if (err.response.status === 405) { //ERRORES DE VALIDATOR

            resp = err.response?.data?.errors?.join('<br>')
        }
        if (err.response.status === 404) {

            resp = "error: " + err.response.statusText
        }
    }

    return resp
}

export const getDistancesList = async (page) => {


    let resp = await apiSecure.post(PATH_GET_DISTANCES + "?page=" + page, { token: localStorage.getItem('token') })

    return resp.data

}

export const guardarCategoria = async (datos) => {


    let resp = await apiSecure.post(PATH_SAVE_DISTANCE_EVENT, { token: localStorage.getItem('token'), datos })
    return resp.data
}

export const submitEvent = async (data) => {


    const formData = new FormData();

    formData.append('lugar', data.eventAddress);
    formData.append('name', data.eventName);
    formData.append('time', data.eventTime);
    formData.append('event_date', data.eventDate);
    formData.append('descripcion', data.eventComment);
    formData.append('flyer', data.eventFlayer);
    formData.append('link_inscripcion', data.eventLinkInscripcion);
    formData.append('enablePayment', data.enablePayment);
    //formData.append('tableCatList', data.tableCatList);

    for (var i = 0; i < data.tableCatList.length; i++) {
        formData.append('tableCatList[]', JSON.stringify(data.tableCatList[i]));
    }

    formData.append('eventFlayerInscripciones', data.eventFlayerInscripciones);
    formData.append('eventFlayerResultados', data.eventFlayerResultados);


    formData.append('token', localStorage.getItem('token'));

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };

    let resp = await apiSecure.post(PATH_SAVE_EVENTS, formData, config)

    return resp

}
export const submitImagenBackground = async (data) => {

    const formData = new FormData();

    formData.append('title', data.eventTitle);
    formData.append('subtitle', data.eventSubtitle);
    formData.append('path', data.eventPath);
    formData.append('token', localStorage.getItem('token'));

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };

    let resp = await apiSecure.post(PATH_SAVE_EVENTS_BGMAIN, formData, config)

    return resp
}

export const getEventsTable = async (page) => {

    let resp = await apiSecure.post(PATH_GET_EVENTS_TABLE + "?page=" + page, { token: localStorage.getItem('token') })
    return resp.data
}

export const getEventBackgroundImage = async (page) => {

    let resp = await apiSecure.post(PATH_GET_EVENTS_BGMAIN + "?page=" + page, { token: localStorage.getItem('token') })
    return resp.data

}
export const getEventInformation = async (id) => {

    let resp = await apiSecure.post(PATH_GET_EVENT_ID, { token: localStorage.getItem('token'), id: id })
    return resp.data
}

export const deleteEventInformation = async (formData) => {
    let resp = await apiSecure.post(PATH_DELETE_EVENTS_TABLE, { token: localStorage.getItem('token'), ...formData })
    return resp
}

export const getBackGroundImagenInfo = async (id) => {

    let resp = await apiSecure.post(PATH_GET_EVENT_BGMAIN_BYID, { token: localStorage.getItem('token'), id: id })
    return resp.data
}

export const deleteBackGroundImagenInfo = async (id) => {

    let resp = await apiSecure.post(PATH_DELETE_EVENT_BGMAIN_BYID, { token: localStorage.getItem('token'), id: id })

    return resp.data
}

export const updateEventInfo = async (data) => {

    const formData = new FormData();

    formData.append('lugar', data.eventAddress);
    formData.append('name', data.eventName);
    formData.append('time', data.eventTime);
    formData.append('event_date', data.eventDate);
    formData.append('descripcion', data.eventComment);
    formData.append('flyer', data.eventFlayer);
    formData.append('event_id', data.event_id);
    formData.append('link_inscripcion', data.eventLinkInscripcion);
    formData.append('link_resultados', data.eventLinkResultados);
    formData.append('enablePayment', data.enablePayment);
    //formData.append('tableCatList', data.tableCatList);
    for (var i = 0; i < data.tableCatList.length; i++) {
        formData.append('tableCatList[]', JSON.stringify(data.tableCatList[i]));
    }
    formData.append('eventFlayerInscripciones', data.eventFlayerInscripciones);
    formData.append('eventFlayerResultados', data.eventFlayerResultados);
    formData.append('status', data.status);





    formData.append('token', localStorage.getItem('token'));

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };

    let resp = await apiSecure.post(PATH_UPDATE_EVENT_ID, formData, config)

    return resp
}
export const updateBackGroundImagenInfo = async (data) => {

    const formData = new FormData();

    formData.append('title', data.eventTitle);
    formData.append('subtitle', data.eventSubtitle);
    formData.append('path', data.eventPath);
    formData.append('image_id', data.image_id);
    formData.append('token', localStorage.getItem('token'));

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };

    let resp = await apiSecure.post(PATH_UPDATE_EVENT_BGMAIN_BYID, formData, config)

    return resp
}

export const getSuscriberList = async (page, filterTable) => {

    let resp = await apiSecure.post(PATH_GET_SUSCRIBER_TABLE + "?page=" + page, { filterTable: filterTable, token: localStorage.getItem('token') })

    return resp.data
}

export const getSuscriberInfo = async (id) => {

    let resp = await apiSecure.post(PATH_GET_SUSCRIBER_ID, { token: localStorage.getItem('token'), id: id })
    return resp.data
}

export const getTallas = async () => {
    let resp = await apiSecure.post(PATH_GET_SUSCRIBERS_TALLAS, { token: localStorage.getItem('token') })
    return resp.data
}


export const updateSuscriberInfo = async (data) => {

    const formData = new FormData();

    formData.append('birthdate', data.eventBirthDate);
    formData.append('cedula', data.eventCedula);
    formData.append('razon_status', data.eventComment);
    formData.append('email', data.eventEmail);
    formData.append('gender', data.eventGender);
    formData.append('instagram', data.eventInstagram);
    formData.append('lastname', data.eventLastname);
    formData.append('name', data.eventNameSuscriber);
    formData.append('phone', data.eventPhone);
    formData.append('talla_id', data.eventTalla);
    formData.append('voucher_img', data.eventVoucher);
    formData.append('suscriber', data.suscriber);


    formData.append('token', localStorage.getItem('token'));
    formData.append('status', data.status);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };

    let resp = await apiSecure.post(PATH_UPDTE_SUSCRIBER, formData, config)

    return resp
}


export const getEventCategory = async (id) => {

    let resp = await apiSecure.post(PATH_GET_DISTANCE_EVENT_BYID, { token: localStorage.getItem('token'), id: id })
    return resp.data
}
export const submitResultados = async (data) => {


    const formData = new FormData();

    formData.append('inputFormName', data.inputFormName);
    formData.append('eventNameSelected', data.eventNameSelected.id);


    data.fileListCategory.forEach(item => {
        formData.append('categorys_img[]', item.file);
    });
    data.fileListCategory.forEach(item => {
        formData.append('categorys_id[]', item.id);
    });

    formData.append('token', localStorage.getItem('token'));

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };

    let resp = await apiSecure.post(PATH_SUBMITRESULTADOS, formData, config)

    return resp
}