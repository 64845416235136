import useAuth from './useAuth';
import { useNavigate } from "react-router-dom";
import { getUserToValidateToken } from '../components/functions/process';


const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate()

    const refresh = async () => {
     
        getUserToValidateToken().then((resp) => {

            let tokenLocalStr =  localStorage.getItem('token') 
           
            let token = tokenLocalStr
            let name = resp.user.name

            setAuth(prev => {
              
                return { ...prev,user:resp.user,token:token,name:name}
            })
            

        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 401) {
                    setAuth({})
                    navigate("/", { replace: true });
                }
                
            }

        })


        if(localStorage.getItem('token')===null){
            setAuth(prev => {
                return {}
            })
       }


    }
    return refresh;
};

export default useRefreshToken;
