import React, { useEffect, useState } from 'react'
import Pagination from '../Pagination'
import Footer from '../Footer'
import Nav from '../Nav'
import ScrollTop from '../ScrollTop'
// import ModalImportExcell from './ModalImportExcell';
import apiSecure from '../../services/servicedba'
import { PATH_DELETE_LOGO_SPONSOR, PATH_LIST_LOGO_SPONSOR, serverHost } from '../../services/utils';
import ModalSubirLogo from './ModalSubirLogo'
// import Select2Multiple from '../Select2Multiple'
// import ModalSubirFlayer from './ModalSubirFlayer'
// import ModalEnvioCorreos from './ModalEnvioCorreos'

export default function Sponsors() {

    const [isOpenModal, setIsOpenModal] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [enableSearch, setEnableSearch] = useState(true)
    const [competidorsList, setCompetidorsList] = useState([])
    const [totalRegister, setTotalRegister] = useState(0)


    useEffect(() => {

        if (enableSearch) {
            ///GET DATA
            const getData = async (page) => {

                let { data } = await apiSecure.post(PATH_LIST_LOGO_SPONSOR + "?page=" + page, { token: localStorage.getItem('token') })


                setCompetidorsList(data.data)
                setLastPage(data.last_page)
                setTotalRegister(data.total)

            }
            getData(currentPage)
            setEnableSearch(false)
        }
    }, [currentPage, enableSearch])





    const modalImportExcellCompetidores = () => {
        setIsOpenModal(true)
    }


    const handleChange = (e, page) => {

        setCurrentPage(page)
        setEnableSearch(true)
    }

    const deletePublicidadImagen = async (id) => {


        const formData = new FormData();

        formData.append('file', id);
        formData.append('token', localStorage.getItem('token'));;

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        };


        try {


            let { data: respon1 } = await apiSecure.post(PATH_DELETE_LOGO_SPONSOR, formData, config)


            if (respon1.message) {
                alert(respon1.message)

                let { data } = await apiSecure.post(PATH_LIST_LOGO_SPONSOR + "?page=" + 1, { token: localStorage.getItem('token') })
                setCompetidorsList(data.data)
                setLastPage(data.last_page)
                setTotalRegister(data.total)
            }

        } catch (error) {

            console.log(error)
        }
    }


    const renderCompetidorList = (item) => {

        return (

            <tr key={item.id}>


                <td className="min-w-120px">
                    <img src={`${serverHost}/${item.image_sponsor}`} width={'20%'} height={'20%'} alt="rullrunners" />
                </td>
                <td className="min-w-120px">
                    <button className='btn btn-danger btn-sm' onClick={() => deletePublicidadImagen(item.id)}>Eliminar</button>
                </td>
            </tr>
        )
    }

    return (
        <div id="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" >

            <div className="d-flex flex-column flex-root">

                <div className="page d-flex flex-row flex-column-fluid">

                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <Nav active={'sponsors'} />

                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="toolbar" id="kt_toolbar">

                                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack mb-4">

                                    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">

                                        <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Sección de Sponsors

                                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        </h1>

                                    </div>


                                </div>

                            </div>

                            <div className="post d-flex flex-column-fluid" id="kt_post">

                                <div id="kt_content_container" className="container-xxl">

                                    <div className="row gy-5 g-xl-8">



                                        <div className="col-xl-11">

                                            <div className="card card-xl-stretch mb-5 mb-xl-8">

                                                <div className="card-header border-0 pt-5">
                                                    <h3 className="card-title align-items-start flex-column">

                                                        <span className="text-muted mt-1 fw-bold fs-7">LISTADO DE PATROCINADORES</span>
                                                    </h3>
                                                    <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="">

                                                        <span href="#" className="btn btn-sm btn-light btn-primary mx-3"
                                                            onClick={() => modalImportExcellCompetidores()}>

                                                            <span className="svg-icon svg-icon-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black" />
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black" />
                                                                </svg>
                                                            </span>
                                                            Subir Logo
                                                        </span>


                                                    </div>
                                                </div>


                                                <div className="card-body py-3">


                                                    <div className="table-responsive">


                                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                                            <thead>
                                                                <tr className="fw-bolder text-muted">
                                                                    <th className="min-w-120px">
                                                                    </th>
                                                                    <th className="min-w-120px">Elmininar</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (competidorsList.length > 0) ?
                                                                        competidorsList.map(renderCompetidorList)
                                                                        :
                                                                        (<>
                                                                            <tr className='text-center'>
                                                                                <td colSpan={6}>

                                                                                    <p className='text-muted'> No data available.</p>
                                                                                </td>
                                                                            </tr>

                                                                        </>)

                                                                }

                                                            </tbody>


                                                        </table>

                                                        <div className='d-flex justify-content-center'>

                                                            <Pagination
                                                                count={lastPage}
                                                                size="small"
                                                                page={currentPage}
                                                                onChange={handleChange}
                                                                variant='outlined'
                                                            />

                                                        </div>
                                                        <span className="text-muted mt-1 fw-bold fs-7">Total Registros: {totalRegister}</span> <span className="text-muted mt-1 fw-bold fs-7">Paginas: {lastPage}</span>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>


            <ModalSubirLogo isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} setEnableSearch={setEnableSearch} />

            <ScrollTop />
        </div>
    )
}
