import React, { useEffect, useRef, useState } from 'react'
import {  PATH_NOIMAGEN_AVAILABLE, PATH_SAVE_LOGO_SPONSOR } from '../../services/utils'
import apiSecure from '../../services/servicedba'



export default function ModalSubirLogo({ isOpenModal, setIsOpenModal, setEnableSearch }) {


    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        successResponse: "",
        errorsResponse: "",
        eventPath: "",
    })

    const [classFade, setClassFade] = useState('')
    const [styleDisplay, setStateDisplay] = useState('none')


    //form
    const [defaultBgImg, setDefaultBgImg] = useState(PATH_NOIMAGEN_AVAILABLE)

    //FORMA DATA
    const [eventPath, setEventPath] = useState('')

    const [errorGlobal, setErrorGlobal] = useState([])
    useEffect(() => {
        setErrors(errorGlobal)
    }, [errorGlobal])


    useEffect(() => {

        if (isOpenModal) {
            setClassFade('show')
            setStateDisplay('block')
            setLoading(false)
            setErrorGlobal([])
            setConfigurationDefault()
        }

    }, [isOpenModal])
    const modalRef = useRef();
    const inputFile = useRef();

    function setConfigurationDefault() {
        setEventPath('')
        setDefaultBgImg(PATH_NOIMAGEN_AVAILABLE)
    }

    const closeModal = () => {
        setDefaultBgImg(PATH_NOIMAGEN_AVAILABLE)

        setClassFade('')
        setStateDisplay('none')
        setIsOpenModal(false)
        setEnableSearch(true)
    }



    const imageFlayerChange = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            setDefaultBgImg(URL.createObjectURL(e.target.files[0]));
            setEventPath(e.target.files[0])
        }

    }

    const setDefaultImageFlayer = () => {

        setDefaultBgImg(PATH_NOIMAGEN_AVAILABLE)
        setEventPath('')
    }


    const handleSubmitCreateEvent = async (event) => {
        event.preventDefault()
        setLoading(true)

        //OBLIGATORIOS
        if (!eventPath) {
            setErrorGlobal({ ...errors, eventPath: "Please select At least 1 imagen" })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, eventPath: "" })

        }


        const formData = new FormData();


        formData.append('file', eventPath);
        formData.append('token', localStorage.getItem('token'));;



        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        };

        try {


            let { data } = await apiSecure.post(PATH_SAVE_LOGO_SPONSOR, formData, config)

            console.log(data)
            if (data.message) {
                setErrorGlobal({ ...errors, successResponse: data.message, errorsResponse: "" })
                if (inputFile.current) {
                    inputFile.current.value = null;  // Limpiar el valor del campo de archivo
                }
                setDefaultBgImg(PATH_NOIMAGEN_AVAILABLE)
                setLoading(false)
                setEventPath('')

            } 

        } catch (error) {

            console.log(error)
            const { response } = error;
            if (response) {

                const { data } = response
            
                if (data?.message) {
                    setErrorGlobal({ ...errors, errorsResponse: data?.message })
                    setLoading(false)
                }
            }
        }


    }

    return (
        <div>

            <div className={"modal fade " + classFade} style={{ display: styleDisplay }} id="kt_modal_invite_friends" tabIndex="-1" aria-hidden="true" data-backdrop="static" aria-labelledby="staticBackdrop" ref={modalRef}>

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">

                        <div className="modal-header pb-0 border-0 justify-content-end">

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1" onClick={() => closeModal()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            <form className="form w-100 " id="kt_sign_in_form_add" action="#" onSubmit={handleSubmitCreateEvent} noValidate>
                                <div className="text-center mb-13">

                                    <h1 className="mb-3">Subir Logo Patrocinador</h1>

                                </div>

                                {
                                    (errors.errorsResponse) ? (<>
                                        <div className="alert alert-danger" role="alert">
                                            {errors.errorsResponse}
                                        </div>
                                    </>
                                    ) : null
                                }
                                {
                                    (errors.successResponse) ? (<>
                                        <div className="alert alert-success" role="alert">
                                            {errors.successResponse}
                                        </div>
                                    </>
                                    ) : null
                                }



                                <div className="separator d-flex flex-center mb-8">
                                    <span className="text-uppercase bg-body fs-7 fw-bold text-muted px-3"></span>
                                </div>

                                <div className='row'>
                                    <div className="fv-row mb-2 text-center">
                                        <p>
                                            {
                                                (!loading) ? <>
                                                    <button className="btn btn-sm btn-light btn-success" disabled={loading}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black">
                                                                </rect></svg>
                                                        </span>Guardar
                                                    </button>
                                                </> : null
                                            }
                                            {
                                                (loading) ? <>

                                                    <span className={"indicator-progress d-block"}>Guardando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                </> : null
                                            }

                                        </p>


                                    </div>



                                    <div className="fv-row mb-2 text-center ">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="flayer">Imagen 	 </label>

                                        <div className={'invalid-feedback mt-2' + ((errors.eventPath) ? " d-block" : null)}>{errors.eventPath}</div>


                                        {
                                            (!eventPath) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerChange}  ref={inputFile}/>


                                                        </label>

                                                    </div>

                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>


                                            ) : null

                                        }
                                        {
                                            (eventPath) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerChange} />


                                                        </label>

                                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"

                                                            data-kt-image-input-action="remove"
                                                            title="Remove avatar"
                                                            onClick={() => setDefaultImageFlayer()}>

                                                            <i className="bi bi-x fs-2"></i>
                                                        </span>



                                                    </div>


                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>

                                            ) : null


                                        }

                                    </div>

                                </div>
                            </form>

                        </div>


                    </div>

                </div>

            </div>


        </div>
    )
}