import React, { useEffect, useRef, useState } from 'react'
import apiSecure from '../../services/servicedba'
import { PATH_SEND_IMPORT_EXCELL } from '../../services/utils'



export default function ModalImportExcell({ isOpenModal, setIsOpenModal, setEnableSearch }) {


    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        successResponse: "",
        errorsResponse: "",
        fileimport: "",
    })

    const [classFade, setClassFade] = useState('')
    const [styleDisplay, setStateDisplay] = useState('none')

    const [fileData, setFileData] = useState('')

    const [errorGlobal, setErrorGlobal] = useState([])


    const modalRef = useRef();
    const inputFile = useRef();

    useEffect(() => {
        setErrors(errorGlobal)
    }, [errorGlobal])


    useEffect(() => {

        if (isOpenModal) {
            setClassFade('show')
            setStateDisplay('block')
            setLoading(false)
            setErrorGlobal([])
            setFileData('')
            // setConfigurationDefault()
            if (inputFile.current) {
                inputFile.current.value = null;  // Limpiar el valor del campo de archivo
            }
        }

    }, [isOpenModal])


    const closeModal = () => {
        if (!loading) {

            setClassFade('')
            setStateDisplay('none')
            setIsOpenModal(false)
            // setEnableSearch(true)
        }

    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFileData(selectedFile);
    };
    const handleSubmitCreateEvent = async (event) => {
        event.preventDefault()
        setLoading(true)


        if (!fileData) {
            setErrorGlobal({ ...errors, fileimport: 'Debe selecionar un archivo excell para continuar.' })
            setLoading(false)
            return false
        }


        const formData = new FormData();

        formData.append('file', fileData);
        formData.append('token', localStorage.getItem('token'));

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        };

        try {

            let { data } = await apiSecure.post(PATH_SEND_IMPORT_EXCELL, formData, config)

            if (data.message) {
                setErrorGlobal({ ...errors, successResponse: data.message, errorsResponse: "" })
                if (inputFile.current) {
                    inputFile.current.value = null;  // Limpiar el valor del campo de archivo
                }
                setLoading(false)
            }

        } catch (error) {

          
            const { response } = error;
            if (response) {

                const { data } = response
                if (data?.errors?.file) {
                    setErrorGlobal({ ...errors, errorsResponse: data.errors.file[0] })
                    setLoading(false)
                }
                if (data?.message) {
                    setErrorGlobal({ ...errors, errorsResponse: data?.message })
                    setLoading(false)
                }
            }
        }

    }

    return (
        <div>

            <div className={"modal fade " + classFade} style={{ display: styleDisplay }} id="kt_modal_invite_friends" tabIndex="-1" aria-hidden="true" data-backdrop="static" aria-labelledby="staticBackdrop" ref={modalRef}>

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">

                        <div className="modal-header pb-0 border-0 justify-content-end">

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1" onClick={() => closeModal()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            <form className="form w-100 " id="kt_sign_in_form_add" action="#" onSubmit={handleSubmitCreateEvent} noValidate>
                                <div className="text-center mb-13">

                                    <h1 className="mb-3">Importar Excell de Competidores</h1>

                                </div>

                                {
                                    (errors.errorsResponse) ? (<>
                                        <div className="alert alert-danger" role="alert">
                                            {errors.errorsResponse}
                                        </div>
                                    </>
                                    ) : null
                                }
                                {
                                    (errors.successResponse) ? (<>
                                        <div className="alert alert-success" role="alert">
                                            {errors.successResponse}
                                        </div>
                                    </>
                                    ) : null
                                }



                                <div className="separator d-flex flex-center mb-8">
                                    <span className="text-uppercase bg-body fs-7 fw-bold text-muted px-3"></span>
                                </div>

                                <div className='row'>
                                    <div className="fv-row mb-2 text-center">
                                        <p>
                                            {
                                                (!loading) ? <>
                                                    <button className="btn btn-sm btn-light btn-success" disabled={loading}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black">
                                                                </rect></svg>
                                                        </span>Guardar
                                                    </button>
                                                </> : null
                                            }
                                            {
                                                (loading) ? <>

                                                    <span className={"indicator-progress d-block"}>Importando datos...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                         <p>Favor no cerrar la pantalla hasta que termine de importar los datos</p>
                                                    </span>
                                                </> : null
                                            }

                                        </p>


                                    </div>



                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="address">Insertar Excell (.xls,.xlsx)</label>

                                        <input className={"form-control form-control-lg "}
                                            ref={inputFile}
                                            id="file"
                                            type="file"
                                            name="file"
                                            autoComplete="off"
                                            placeholder='Insertar Excell (.xls,.xlsx)'
                                            accept=".xls,.xlsx" // Atributo para aceptar solo archivos Excel   
                                            onChange={handleFileChange}
                                        />

                                        <div className={'invalid-feedback' + ((errors.fileimport) ? " d-block" : null)}>{errors.fileimport}</div>

                                    </div>

                                </div>
                            </form>

                        </div>


                    </div>

                </div>

            </div>


        </div>
    )
}