import React, { useEffect, useState } from "react";
import iconprofile from "../img/logo-fullrunner.svg";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";




const Nav = ({ active }) => {
    const { auth } = useAuth()
    const [user, setUser] = useState('')
    const [name, setName] = useState('')

    const [logoIconOpen, setlogoIconOpen] = useState(false)
    const [activeMobileMenu, setActiveMobileMenu] = useState(false)


    const navigate = useNavigate()


    useEffect(() => {
        if (auth.user) {
            setName(auth.user.name)
            setUser(auth.user.email)
        }

        //  console.log('aa',auth)
    }, [auth])


    const openLogoMenuOpen = () => {
        setlogoIconOpen(!logoIconOpen)
    }

    const openMobileMenu =() => {
        setActiveMobileMenu(!activeMobileMenu)
    }
  
    return (

        <>

            <div id="kt_header" className="header align-items-stretch">

                <div className="container-fluid d-flex align-items-stretch justify-content-between">




                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0" onClick={()=>openMobileMenu()}>
                        <div className="d-flex align-items-stretch d-lg-none px-3 me-n3" title="Show header menu">
                            <div className="topbar-item" id="kt_header_menu_mobile_toggle2">
                                <i className="bi bi-text-left fs-1"></i>
                            </div>
                        </div>
                    </div>


                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">

                        <div className="d-flex align-items-stretch" id="kt_header_nav">
                        {/* header-menu align-items-stretch */}
                            <div className={`header-menu align-items-stretch ${activeMobileMenu ? 'drawer drawer-end drawer-on ':''}`}
                                data-kt-drawer="true"
                                data-kt-drawer-name="header-menu"
                                data-kt-drawer-activate="{default: true, lg: false}"
                                data-kt-drawer-overlay="true"
                                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction="end"
                                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle2"
                                data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">

                                <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                                    id="#kt_header_menu" data-kt-menu="true">

                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
                                        <span className={"menu-link py-3" + (active === "eventos" ? " active" : "")} onClick={() => { navigate('/eventos', { replace: true }); }}>
                                            <span className="menu-title">Eventos</span>
                                        </span>
                                    </div>

                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
                                        <span className={"menu-link py-3" + (active === "bground-slider-main" ? " active" : "")} onClick={() => { navigate('/bground-slider-main', { replace: true }); }}>
                                            <span className="menu-title">Bakground</span>
                                        </span>
                                    </div>


                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
                                        <span className={"menu-link py-3" + (active === "competidores" ? " active" : "")} onClick={() => { navigate('/competidores', { replace: true }); }}>
                                            <span className="menu-title">Módulo Competidores</span>
                                        </span>
                                    </div>

                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
                                        <span className={"menu-link py-3" + (active === "publicidad" ? " active" : "")} onClick={() => { navigate('/publicidad', { replace: true }); }}>
                                            <span className="menu-title">Módulo Publicidad</span>
                                        </span>
                                    </div>


                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
                                        <span className={"menu-link py-3" + (active === "sponsors" ? " active" : "")} onClick={() => { navigate('/sponsors', { replace: true }); }}>
                                            <span className="menu-title">Módulo Sponsors</span>
                                        </span>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="d-flex align-items-stretch flex-shrink-0">

                            <div className="topbar d-flex align-items-stretch flex-shrink-0">


                                <div className="d-flex align-items-stretch" id="kt_header_user_menu_toggle">

                                    <div className="topbar-item cursor-pointer symbol px-3 px-lg-5 me-n3 me-lg-n5 symbol-30px symbol-md-35px  show menu-dropdown "
                                        data-kt-menu-trigger="click"
                                        onClick={() => openLogoMenuOpen()}
                                        data-kt-drawer-direction="buttom"
                                    >
                                        <img src={iconprofile} alt="metronic" />
                                    </div>

                                    <div className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px ${logoIconOpen ? 'show' : ''}`}
                                        data-kt-menu="true"
                                        style={{
                                            zIndex: 105,
                                            position: 'fixed',
                                            inset: '0px 0px auto auto',
                                            margin: 0,
                                            transform: 'translate(-62px, 0px)',
                                        }}
                                        data-popper-placement="left" >

                                        {/* class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px show" data-kt-menu="true" */}
                                        <div className="menu-item px-3">
                                            <div className="menu-content d-flex align-items-center px-3">

                                                <div className="symbol symbol-50px me-5">
                                                    <img alt={"Logo"} src={iconprofile} />
                                                </div>


                                                <div className="d-flex flex-column">
                                                    <div className="fw-bolder d-flex align-items-center fs-5">{name}
                                                        <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Administrador</span></div>
                                                    <span className="fw-bold text-muted text-hover-primary fs-7">{user}</span>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="separator my-2"></div>




                                        <div className="menu-item px-5">
                                            <a href="/logout" className="menu-link px-5">Salir</a>
                                        </div>


                                        <div className="separator my-2"></div>



                                    </div>


                                </div>




                            </div>

                        </div>

                    </div>

                </div>

            </div>


        </>

    )

}

export default Nav
